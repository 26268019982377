import { useCalendarStore } from "../store/calendarStore";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function useCalendar() {
  const token = useCalendarStore((state) => state.token);
  useEffect(() => {
    console.log('useAdminPendingApproval:::');
  }, []);

  return {
    token
  };
}

export default useCalendar;
