import useLoader from "../../../hooks/useLoader";



function FetchSync({ 
  doCalendarSyncUp,
  doViewCalendar
}) {
  const { showLoader } = useLoader();
  return (
    <>
      <div
        className="book-appointment-button"
        onClick={async () => {
          let objData = {}
          objData.code = '4%2F0ASVgi3IqDokMXOIOag4DKQbJnF-6uEIsGox5K9QcWNFD8CwtuprE4XO-2z6LlLRp9bkO3Q';
          objData.scope = 'scope';
          showLoader("Fetching Booking");
          const calendarSyncUp = await doCalendarSyncUp(objData);
          console.log(calendarSyncUp);
        }}
      >
        {/* API call for calendar sync up */}
        Manual Fetch sync-up
      </div>

      <div
        className="book-appointment-button"
        onClick={async () => {

          let objData = { bearer: 'ya29.a0AXeO80QYR5-CS4JNCm5r-HjOHibIytzaHXlyCoQ_XL-Rs8aFsBIV9CNpvgGVCNLX0-Rti0gLly7CoGtMFIB5neu9GAOANCB5yWZrZpAa39eHStqfSFn83Tuv6s2M_sdx-K9wXA7eJ-hPX_N1FneXiY7McfVAihj5Kt7bi-u6MwaCgYKAQ4SARMSFQHGX2MiwoKj7hL1A4f-lwdKOkWszw0177' }

          showLoader("Fetching Booking");
          const calendarSyncUp = await doViewCalendar(objData);
          console.log(calendarSyncUp);
        }}
      >
        {/* API call for calendar sync up */}
        Fetch Calendar Data
      </div>
    </>
  );
}

export default FetchSync;
