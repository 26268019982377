import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import {
  getGoogleCalendarToken,
  getGoogleCalendarView
} from "../api";

const initialState = {
  token: [],
};

const store = (set, get) => ({
  //state
  ...initialState,
  setToken: (state) => set({ token: state }),
  doReset: () => set(initialState),
  doCalendarSyncUp: async (data) => {
    try {
      const tokenResponse = await getGoogleCalendarToken(data);
      set({ token: 'eewsd112fdpob' }); // Test sample google API token. Todos: to be generated from API
    } catch(e) {

    }
  },
  doViewCalendar: async (data) => {
    try {
      const tokenResponse = await getGoogleCalendarView(data);
    } catch(e) {

    }
  }
});

export const useCalendarStore = create(devtools(immer(store)));
