import IconGlobe from "../../assets/images/icon_globe.png";
import OrangeUp from "../../assets/images/icon_orange_up.png";
import OrangeDown from "../../assets/images/icon_orange_down.png";
import Select from "react-select";

import {
  durationOptions,
  monthsOptions,
  timezoneOptions,
} from "../../utils/globals";
import { useState } from "react";
import {
  inflateValuesFromSelect,
  formatUniversalDate,
} from "../../utils/utilityFunctions";
import Calendar from "react-calendar";
import FetchSync from "./form/FetchSync"
import { getDayName } from "../../utils/utilityFunctions";
import { useCalendarStore } from "../../store/calendarStore"
import useCalendar from "../../hooks/useCalendar"

function PractitionerSchedule({
  practitionerId,
  doSetAppointment,
  showLoader,
  showMessage,
  closeMessages,
  showError,
  appointments,
}) {
  const [timezone, setTimezone] = useState();
  const [duration, setDuration] = useState("30");
  const [value, setValue] = useState(null);
  const [hour, setHour] = useState();
  const [partOfDay, setPartOfDay] = useState("AM");

  const { doCalendarSyncUp, doViewCalendar } = useCalendarStore();
  const { token } = useCalendar();
  console.log('token:::', token);

  const daysAvailable = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const onChange = (data) => {
    setValue(data);
  };

  const getSelectedDate = (date) => {
    if (!date) {
      return "No selected date.";
    }

    const dayName = getDayName(date);
    if (!daysAvailable.includes(dayName)) {
      return "No selected date.";
    }

    return (
      getDayName(date) +
      ", " +
      monthsOptions[date.getMonth()].label +
      " " +
      date.getDate()
    );
  };

  const getHoursList = (duration) => {
    const result = durationOptions.find((item) => {
      return item.value === duration;
    });
    return result.data;
  };

  const onTileRender = (params) => {
    var className = "custom-calendar-tile";
    var isAvailableThisDay = false;
    const tileDate = new Date(params.date);
    const tileDay = getDayName(tileDate);

    if (daysAvailable.includes(tileDay)) {
      isAvailableThisDay = true;
      className += " is-available-this-day";
    }

    return (
      <div className={className}>
        <div className="date-number">{tileDate.getDate()}</div>
      </div>
    );
  };

  const bookAppointment = async () => {
    if (!value || !hour) {
      showError("Please select date and time.");
      return;
    }

    showLoader("Saving booking..");
    let payload = {
      notes: "...",
      practitioner_id: practitionerId,
      duration: duration,
      slot_date: formatUniversalDate(value),
      slot_time_start: hour,
    };
    console.log("payload::", payload);
    const response = await doSetAppointment(payload);
    closeMessages();
    if (response.error) {
      showError(response.message);
      return;
    }
    showMessage("Appointment Successfully Booked");
  };

  const isDateBooked = (date, appointments) => {
    for (let i = 0; i < appointments.length; i++) {
      const appointment = appointments[i];
      let start = new Date(appointment.slot_date_start);
      let end = new Date(appointment.slot_date_end);

      //Check if time slot is within an existing appointment.
      if (date.getTime() >= start.getTime() && date.getTime() < end.getTime()) {
        return true;
      }
    }

    for (let i = 0; i < appointments.length; i++) {
      const appointment = appointments[i];
      let start = new Date(appointment.slot_date_start);
      let end = new Date(appointment.slot_date_end);

      const plus = parseInt(duration) * 60 * 1000;
      const thisDate = new Date(date.getTime());
      const thisDatePlusDuration = new Date(date.getTime() + plus);

      //Check if this time slot + duration has conflict with existing appointments.
      if (
        thisDate.getTime() < end.getTime() &&
        thisDatePlusDuration.getTime() > start.getTime()
      )
        return true;
    }

    return false;
  };

  return (
    <>
      <div className="practitioner-schedule">
        <div className="book-container">
          <div className="separator"></div>
          <div className="row">
            <div className="col-4">
              <h3>Online Clinic Schedule</h3>

              <div className="schedule-message">
                Hi! My regular consultation hours may change on certain
                circumstances, but rest assured you'll be notified 1-2 days
                prior!
              </div>

              <div className="input-wrapper">
                <div>
                  <img className="icon-globe" src={IconGlobe}></img>
                </div>
                <div>
                  <Select
                    placeholder={"Select Timezone"}
                    classNamePrefix={"timezone-select"}
                    options={timezoneOptions}
                    isSearchable={false}
                    value={
                      timezone
                        ? inflateValuesFromSelect([timezone], timezoneOptions)
                        : null
                    }
                    onChange={(e) => {
                      setTimezone(e.value);
                    }}
                  ></Select>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="calendar-container">
                <Calendar
                  calendarType="US"
                  onChange={onChange}
                  value={value}
                  defaultView="month"
                  showNavigation={true}
                  tileContent={onTileRender}
                  tileDisabled={({ date }) =>
                    !daysAvailable.includes(getDayName(date))
                  }
                />
              </div>
            </div>
            <div className="col-4 text-center">
              <div className="session-duration">Session Duration</div>
              <Select
                classNamePrefix={"duration-select"}
                options={durationOptions}
                isSearchable={false}
                value={
                  duration
                    ? inflateValuesFromSelect([duration], durationOptions)
                    : null
                }
                onChange={(e) => {
                  setDuration(e.value);
                }}
              ></Select>

              <div className="selected-date">{getSelectedDate(value)}</div>

              {partOfDay == "PM" && (
                <div
                  className="AMPM-button"
                  onClick={() => {
                    setPartOfDay("AM");
                  }}
                >
                  Morning <img src={OrangeUp}></img>
                </div>
              )}

              {duration && (
                <div className="hour-list">
                  {getHoursList(duration).map((item) => {
                    let currentItemDate = null;
                    let isBooked = false;
                    if (value && appointments) {
                      currentItemDate = new Date(
                        formatUniversalDate(value) + " " + item
                      );
                      isBooked = isDateBooked(currentItemDate, appointments);
                    }
                    let className = "session-hour";
                    if (hour === item) className = "session-hour-active";
                    if (isBooked) className += " session-hour-booked";

                    if (item.includes(partOfDay))
                      return (
                        <div
                          className={className}
                          onClick={() => {
                            setHour(item);
                          }}
                        >
                          {item}
                        </div>
                      );
                  })}
                </div>
              )}

              {partOfDay == "AM" && (
                <div
                  className="AMPM-button"
                  onClick={() => {
                    setPartOfDay("PM");
                  }}
                >
                  Afternoon <img src={OrangeDown}></img>
                </div>
              )}
              <div
                className="book-appointment-button"
                onClick={async () => await bookAppointment()}
              >
                Schedule Appointment
              </div>

              <FetchSync
              doCalendarSyncUp={doCalendarSyncUp}
              showLoader={showLoader}
              doViewCalendar={doViewCalendar}
              />

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PractitionerSchedule;
